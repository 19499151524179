import React from 'react';
import { Link } from 'gatsby';
import {Grid, Col, Row} from 'react-styled-flexboxgrid';

// import '../../assets/sass/main.scss';

const Header = () => (
  <Grid>
    <Row>
      <Col xs={12}>
        <header id="header">
          <Link className="title" to="/">
            Hyperspace
          </Link>
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/generic">Generic</Link>
              </li>
              <li>
                <Link to="/elements">Elements</Link>
              </li>
            </ul>
          </nav>
        </header>
      </Col>
    </Row>
  </Grid>
);

export default Header;
